.img-section {
  position: relative;
  width: 100%;
  height: 101vh;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-section img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
.img-section img#moon {
  mix-blend-mode: screen;
}
.img-section img#ocean {
  z-index: 14;
}
#btn {
  text-decoration: none;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 40px;
  background: #fff;
  color: #2b1055;
  font-size: 1.5em;
  z-index: 9;
}
@media (min-width: 770px) {
  .background-padding {
    height: 100px;
    width: 100%;
    /*     background: linear-gradient(to top, #000468, transparent); */
    background: linear-gradient(to top, #05274c, transparent);
    position: absolute;
    top: 91vh;
    z-index: 16;
  }
}
