.content-b {
  position: relative;
  padding: 50px 25px;
  /*   background: #000468; */
  /* background: #05274c; */
}
.content-t {
  position: absolute;
  top: 0;
  /*   background: #000468; */
  /* background: #05274c; */
}
.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-bottom: 0.8rem;
}
.col_t12_x4 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}
.col_t12_x8 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}
.col_t12_x12 {
  flex-basis: 100%;
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 770px) {
  .col_t12_x8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col_t12_x4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
}
