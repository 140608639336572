@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap");

.navigation {
  position: fixed;
  left: 0;
  top: 78vh;
  width: 100%;
  height: 8vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}
.navigation ul {
  display: flex;
  justify-content: space-around;
  width: 90%;
}
.navigation ul li {
  list-style: none;
  position: relative;
  /* 350 / 5 = 70 */
  z-index: 2;
}
.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 65px;
  width: 1em;
  font-size: 1.5em;
  transition: 0.5s;
  color: #222327;
}
.navigation ul li a.active .icon {
  transform: translateY(-36px);
  color: #2196f3;
}
.navigation ul li a .text {
  position: absolute;
  background: #2196f3;
  color: #fff;
  padding: 2px 7px;
  border-radius: 12px;
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(15px);
}
.navigation ul li a.active .text {
  transform: translateY(-4px);
  opacity: 1;
}
.indicator {
  position: absolute;
  top: -35px;
  left: 0;
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
  transition: 0.5s;
}
.indicator::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 15px 18px #fff;
}
.indicator::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: -15px 18px #fff;
}
@media (min-width: 770px) {
  .navigation {
    display: none;
  }
}
