.slider {
  padding-top: 2em;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: #7597de;
  box-shadow: 0 -1px 0 #e0e0e0, 0 0 2px rgba(0, 0, 0, 0.12),
    0 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
}
.slide_wrap {
  max-width: 17rem;
  margin: auto;
}
.slide_box {
  width: 100%;
  margin: auto;
  overflow-x: hidden;
  margin-bottom: 1.5rem;
}
.slide_content {
  display: table;
  float: left;
  width: 17rem;
  height: 17rem;
  text-align: center;
}

.slide_image {
  max-width: 100%;
  height: 17rem;
}

.slide_pagination {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 3.5rem;
}
.dot {
  list-style-type: disc;
  font-size: 1.8rem;
  cursor: pointer;
  margin-right: 1rem;
  color: #999999;
}
.dot_active {
  list-style-type: disc;
  font-size: 1.8rem;
  color: #000000;
}
