header {
  display: none;
}
@media (min-width: 770px) {
  header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10000;
  }
  header .logo {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    font-size: 2em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  header ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header ul li {
    list-style: none;
    margin-left: 20px;
  }
  header ul li a {
    color: #fff;
    border-radius: 20px;
    text-decoration: none;
    padding: 6px 15px;
  }
  header ul li a:hover,
  header ul li a.active {
    background: #fff;
    color: #2b1055;
  }
}
