a:hover .project-item {
  transform: translateY(-10px);
  z-index: 16;
}

.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-in-out;
  z-index: 15;
}

.project-img-box {
  position: relative;
  overflow: hidden;
  border-radius: 0.5em;
  z-index: 15;
}
.project-img-box img {
  width: 100%;
  border-radius: 0.5em;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  height: auto;
  transition: all 0.2s ease-in-out;
  z-index: 15;
}
.project-item:hover .project-img-box > img {
  transform: scale(1.1);
  z-index: 16;
}
.project-category {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #def7ec;
  padding: 0.125em 0.75em;
  border-radius: 9999px;
  z-index: 15;
}
.project-explanation {
  border: 1px solid gray;
  margin-top: -2em;
  margin-bottom: 2em;
  padding: 0.6em;
  width: 80%;
  background: #ffffff;
  text-align: center;
  border-radius: 0.5em;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  z-index: 16;
}
.project-title {
  font-size: 1.25em;
}
.project-brief {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}
