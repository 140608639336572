@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
body {
  min-height: 100vh;
  overflow-x: hidden;
  /* background: linear-gradient(#000468, #7597de); */
  /* background: linear-gradient(#05274c, #7597de); */
  background: #05274c;
}
