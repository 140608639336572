.panel {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  background: hsla(0, 0%, 100%, 0.4);
  /* background: #2baddb; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.panel h3 {
  flex: 0 0 100%;
  margin: 10px 5px 0px 5px;
  font-weight: 600;
  font-size: 1.8em;
  color: #ffffff;
}
.panel-content {
  display: flex;
  align-items: center;
  padding: 0.2em 0.5em;
  color: #ffffff;
}
.panel-icon {
  align-self: flex-start;
  display: block;
  width: 3rem;
  font-size: 2rem;
  margin: 0 5px;
}
.panel-icon i {
  width: 3em;
}
.panel-desc {
}
